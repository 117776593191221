import PropTypes from 'prop-types';
import { useEffect, useState, useSyncExternalStore } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import { useInterval } from 'react-use';
import * as Sentry from '@sentry/react';

import CenteredAlert from '../components/centered-alert';
import Link from '../components/link';
import { useSnackbar } from '../components/snackbar/context';

import API from '../api';
import {
    fetchCurrentUser,
    fetchCarrier,
    getIsAuthenticated,
    getIsAuthenticating,
    getCurrentCarrier,
    getIsCarrierLoading,
    getIsAdmin,
    getUserCarrierId,
    getIsHinshawAdmin,
    logout,
} from '../redux/auth';

import * as AuthUtils from '../utils/auth';
import WebClient, { isAuthErrorResponse } from '../utils/web-client';
import useRedirect from '../utils/use-redirect';

// Intuition for these auth wrappers: expected that any state checked in these is either
// loaded prior to render and in the process of loading at the time of render. we redirect away b/c
// bad state means we can't render the UI we want to, so, app should
// be in state of looking like there's potential to render prior to rendering a given guard
// Less word-soupy, when navigating across an auth boundary, we need to kick off loading any
// prerequisite data prior to navigation, so we hit the new auth wrapper at least at loading
// See the carriers route, where we kick off fetching the selected carrier on clicking a carrier card

// if user is authenticated, redirect to the auth'd view, where they can likely take any public actions and more
const PublicGuard = ({ children }) => {
    const userCarrierId = useSelector(getUserCarrierId);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const isAdmin = useSelector(getIsAdmin);
    const location = useLocation();

    // public routes that auth'd users should still be allowed to view (e.g. linked in the footer, still relevant after crossing auth boundary)
    if (
        isAuthenticated &&
        !['/terms-and-conditions', '/privacy-policy', '/lawyer-advertising', '/error/404', '/error/500'].includes(
            location.pathname,
        )
    ) {
        if (!userCarrierId && isAdmin) {
            return <Navigate replace to="/carriers" />;
        }

        return <Navigate replace to="/dashboard" />;
    }

    return children;
};

PublicGuard.propTypes = {
    children: PropTypes.node.isRequired,
};

const AuthenticatedGuard = ({ children }) => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const isAuthenticating = useSelector(getIsAuthenticating);

    if (isAuthenticating) {
        return <Skeleton variant="rectangular" width="100%" animation="wave" height="100vh" />;
    }

    if (!isAuthenticated) {
        return <Navigate replace to="/" />;
    }

    return children;
};

AuthenticatedGuard.propTypes = {
    children: PropTypes.node.isRequired,
};

const AdminOnlyGuard = ({ children }) => {
    const isAdmin = useSelector(getIsAdmin);

    if (!isAdmin) {
        return <Navigate replace to="/dashboard" />;
    }

    return children;
};

AdminOnlyGuard.propTypes = {
    children: PropTypes.node.isRequired,
};

const ExcludeHinshawGuard = ({ children }) => {
    const isHinshawAdmin = useSelector(getIsHinshawAdmin);

    if (isHinshawAdmin) {
        return <Navigate replace to="/dashboard" />;
    }

    return children;
};

ExcludeHinshawGuard.propTypes = {
    children: PropTypes.node.isRequired,
};

// Note this is reacting only to changes in local storage made by other tabs/windows, including manually in dev tools, not changes made by this tab/window
// So all we're really doing here is using getSnapshot to make the localStorage value available at render time
// if we need to react to changes made by this tab/window, we'd need a different approach e.g. https://github.com/astoilkov/use-local-storage-state/tree/main
// or maybe even react-use's useLocalStorage.
const subscribe = (callback) => {
    window.addEventListener('storage', callback);
    return () => window.removeEventListener('storage', callback);
};

const getSnapshot = () => localStorage.getItem('adminSelectedCarrier');

const CarrierGuard = ({ children }) => {
    const currentCarrier = useSelector(getCurrentCarrier);
    const isCarrierLoading = useSelector(getIsCarrierLoading);
    const adminSelectedCarrier = useSyncExternalStore(subscribe, getSnapshot);
    const isAdmin = useSelector(getIsAdmin);

    // Condition basically means: if we're an admin, and we don't have a carrier selected, and we don't have a carrier selected in localStorage, redirect to carriers
    // !currentCarrier is checked to allow passing through if we somehow end up with a carrier in state, even if
    // there's none in localStorage; that shouldn't happen, but no reason to degrade the app if localStorage fails for whatever reason
    const adminNeedsToSelectCarrier = isAdmin && !currentCarrier && !adminSelectedCarrier;

    useRedirect(adminNeedsToSelectCarrier, '/carriers', {
        message: 'Please select a carrier to continue',
        severity: 'warning',
    });

    if (isCarrierLoading) {
        return null;
    }

    if (adminNeedsToSelectCarrier) {
        // redirection handled by the effect (useRedirect) above; this
        // just blocks rendering until that effect runs
        return null;
    }

    if (!currentCarrier) {
        // Carrier failed to load for a user that should have perms to it
        return (
            <CenteredAlert severity="error">
                Failed to load carrier information. Please contact the{' '}
                <Link href="mailto:info.lawyeringlaw@hinshawlaw.com" sx={{ color: 'warning.main' }}>
                    site administrator
                </Link>{' '}
                if issues continue
            </CenteredAlert>
        );
    }

    return children;
};

CarrierGuard.propTypes = {
    children: PropTypes.node.isRequired,
};

const refreshAuth = async (dispatch) => {
    // reauthenticate user if token is still valid
    const user = await dispatch(fetchCurrentUser({ reauthenticating: true })).unwrap();

    // We don't throw these carrier fetching errors, as we deal with errors elsewhere
    // - 401s: handled by response interceptor in routes/index.js
    // - everything else: handled in the withCarrier guard above. As in, if we don't end up with a carrier in state
    // the user sees the logged-in layout, but with the custom carrier error message displayed
    if (AuthUtils.isAdmin(user)) {
        // load prev selected carrier, if any, from localStorage
        // so admins don't have to reselect a carrier every time they visit the app

        const carrierId = localStorage.getItem('adminSelectedCarrier');

        if (carrierId) {
            await dispatch(fetchCarrier({ carrierId }));
        }
    } else {
        await dispatch(fetchCarrier({ carrierId: AuthUtils.getUserCarrierId(user) }));
    }
};

CarrierGuard.propTypes = {
    children: PropTypes.node.isRequired,
};

// If a user has previously authenticated, on revisiting the application, they should resume that state
// An auth token is not enough information to tell if the user is authenticated; we must verify
// that by passing to the API first; until we have enough information to make that call, we should
// not render any UI that shows actions and data that require authentication, that only registered
// users should be able to see
// We proceed once attempt settles, successful or not, allowing guard HOCs above to handle redirecting
// the user to the route expected based on their authentication result
//
// Principle: the API should always be the source of truth for application rules. Just b/c the UI
// allows something illegal, doesn't mean the API will or should. Therefore, the UI should
// align with the API, showing views reflective of the user's abilities within the system
const Gate = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isReady, setIsReady] = useState(false);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const { openSnackbar } = useSnackbar();

    useInterval(
        async () => {
            // Polling API to verify inactivity timeout
            // Essentially a healthcheck endpoint, except the API doesn't count
            // requests to this endpoint as activity, so hitting it doesn't slide
            // the inactivity timeout. So, by polling this endpoint, if the user
            // doesn't do anything else (trigger API requests), eventually we'll
            // receive a 401 due to crossing our inactivity timeout, which will trigger
            // our auto-logout logic via the response interceptor below
            try {
                await API.authCheck();
            } catch (err) {
                const isDev =
                    process.env.NODE_ENV === 'development' || process.env.REACT_APP_DEPLOY_ENV === 'development';
                // The above call throwing lands in Sentry; 401s here are fine, considered normal operations,
                // so we take care to filter those out, reporting only the system errors that are truly unexpected
                if (!isAuthErrorResponse(err)) {
                    if (isDev) {
                        // eslint-disable-next-line no-console
                        console.error('authCheck', err);
                    }

                    throw err;
                }
            }
        },
        isAuthenticated ? 1000 * 60 : null, // null disables the interval
    );

    useEffect(() => {
        const refreshFn = async () => {
            try {
                /*
                    During rollout, one user reported being unable to login. We met with him and discovered the root cause
                    was he was on a VPN, which we assumed disrupted accessing the site somehow. We can't solve for
                    that, but we can be more proactive about informing the user that they can't access the site and potentially why.
                    
                    So, we run this health check to catch any holistically-blocking issues like that (or, say, our API is outright down)
                    and redirect to a 500 page such that the user at least expects the site to be down vs. trying to login or doing
                    something else and getting frustrated that the site isn't working.
                */
                await API.healthCheck();
            } catch (err) {
                /*
                    We capture here because we assume the health check is just an echo, so an error here 
                    most likely indicates an issue outside the API i.e. not capturable by the API's sentry instance.

                    For example, either an API misconfiguration (Zack screwed up setting up the health check endpoint or
                    setting it to public in plugin settings) OR, of greater interest / actually observed with users, a 
                    networking issue blocked requests to the API.
                */
                Sentry.captureException(err);
                navigate('/error/500');
                setIsReady(true);
                return;
            }

            try {
                // No token, no need to bother trying to refresh auth, since it'll definitely fail
                if (localStorage.getItem('authToken')) {
                    await refreshAuth(dispatch);
                }
            } catch (err) {
                // auth error responses handled by response interceptor (see below)
                if (!isAuthErrorResponse(err)) {
                    openSnackbar({
                        message: 'Something went wrong! Please contact the site administrator if issues continue',
                        severity: 'error',
                    });
                }
            } finally {
                setIsReady(true);
            }
        };

        // Intention is to reset the app if the user's authentication appears to have lapsed e.g. token expired
        // Potential concerns:
        // - can users still visit public views without being redirected to login? Including, edge case: they have a token, but it's expired, and try
        // to visit a public view e.g. forgot password. We don't want to kick them to login
        // - are there 401 responses that should not redirect to login? do we need to check errors more specifically?

        const interceptor = WebClient.interceptors.response.use(null, async (error) => {
            if (
                isAuthErrorResponse(error) &&
                // Necessary to check if we're already in the process of logging out, to avoid infinite loop
                // otherwise, we'd hit the logout thunk, which would hit the response interceptor, which would hit the logout thunk, etc.
                // control flow would never return to the original call site (i.e. our logout thunk, which tries to delete
                // the authToken from localStorage, but never gets there b/c the interceptor keeps re-triggering the logout thunk)
                error.config.url !== 'll/v1/logout'
            ) {
                // Must order this before logout, since logout clears our token
                const wasLoggedIn = !!localStorage.getItem('authToken');

                try {
                    await dispatch(logout()).unwrap();
                } catch (err) {
                    if (!isAuthErrorResponse(err)) {
                        openSnackbar({
                            message: 'Something went wrong! Please contact the site administrator if issues continue',
                            severity: 'error',
                        });
                    } else if (wasLoggedIn) {
                        // notify user if it looks like they were logged out due to token expiration
                        openSnackbar({ message: 'Your session has expired. Please login again.', severity: 'warning' });
                    }
                }

                // Auth protections must be enforced at render in whichever layouts are protected e.g. redirect to login if not authenticated
            }
            throw error;
        });

        // Try to refresh auth state, if any, opening the gate (rendering routes) regardless of result once our work's done
        refreshFn();

        return () => {
            WebClient.interceptors.response.eject(interceptor);
        };
        // Rule disabled b/c it's imperative this effect run once and only one, on app init
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isReady) {
        return <Skeleton variant="rectangular" width="100%" animation="wave" height="100vh" />;
    }

    return children;
};

Gate.propTypes = {
    children: PropTypes.node.isRequired,
};

export { PublicGuard, AuthenticatedGuard, AdminOnlyGuard, ExcludeHinshawGuard, CarrierGuard, Gate, refreshAuth };
