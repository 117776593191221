import { Suspense, lazy } from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { Gate, PublicGuard, AuthenticatedGuard, AdminOnlyGuard, ExcludeHinshawGuard, CarrierGuard } from './guard';
import PublicLayout from '../layouts/public';
import MainLayout from '../layouts/main';
import { SentryRoutes } from '../utils/sentry';
import LegalPolicy, { LEGAL_POLICY_TYPES } from './legal';

const Login = lazy(() => import('./login'));
const LoginSetupMFA = lazy(() => import('./login/setup-mfa'));
const LoginConfirmMFA = lazy(() => import('./login/confirm-mfa'));
const ForgotPassword = lazy(() => import('./login/forgot-password'));
const ResetPassword = lazy(() => import('./login/reset-password'));
const Error404 = lazy(() => import('./error/404'));
const Error500 = lazy(() => import('./error/500'));
const ErrorSentry = lazy(() => import('./error/sentry'));
const CarriersList = lazy(() => import('./carriers'));
const Dashboard = lazy(() => import('./dashboard'));
const ResourceSearch = lazy(() => import('./resources/search'));
const ResourceDetail = lazy(() => import('./resources/detail'));
const ResourceCategories = lazy(() => import('./categories'));
const Signup = lazy(() => import('./signup'));
const Profile = lazy(() => import('./profile'));
const AboutUs = lazy(() => import('./about-us'));
const RiskConsultation = lazy(() => import('./risk-consultation'));
const AzureLoginRedirect = lazy(() => import('./azure-login-redir'));

const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_DEPLOY_ENV === 'development';

const CompleteAuthLayout = () => (
    <CarrierGuard>
        <Outlet />
    </CarrierGuard>
);

const App = () => (
    <Suspense fallback={<div />}>
        <Gate>
            <SentryRoutes>
                <Route
                    element={
                        <PublicGuard>
                            <PublicLayout />
                        </PublicGuard>
                    }
                >
                    <Route path="/" element={<Login />} />
                    <Route path="/azure-login" element={<AzureLoginRedirect />} />
                    <Route path="/login/setup/:proofToken" element={<LoginSetupMFA />} />
                    <Route path="/login/confirm/:proofToken" element={<LoginConfirmMFA />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/terms-and-conditions" element={<LegalPolicy type={LEGAL_POLICY_TYPES.TERMS} />} />
                    <Route path="/privacy-policy" element={<LegalPolicy type={LEGAL_POLICY_TYPES.PRIVACY} />} />
                    <Route
                        path="/lawyer-advertising"
                        element={<LegalPolicy type={LEGAL_POLICY_TYPES.LAWYER_ADVERTISING} />}
                    />
                    {/* Global error pages */}
                    <Route path="/error/404" Component={Error404} />
                    <Route path="/error/500" Component={Error500} />
                </Route>
                {/* Authenticated routes */}
                <Route
                    element={
                        <AuthenticatedGuard>
                            <MainLayout />
                        </AuthenticatedGuard>
                    }
                >
                    <Route
                        path="/carriers"
                        element={
                            <AdminOnlyGuard>
                                <CarriersList />
                            </AdminOnlyGuard>
                        }
                    />

                    <Route element={<CompleteAuthLayout />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/tip-of-the-month" element={<ResourceSearch />} />
                        <Route path="/newsletter" element={<ResourceSearch />} />
                        <Route path="/cles" element={<ResourceSearch />} />
                        <Route path="/resources" element={<ResourceSearch />} />
                        <Route path="/resources/:resourceId" element={<ResourceDetail />} />
                        <Route path="/categories" element={<ResourceCategories />} />
                        <Route path="/about-us" element={<AboutUs />} />
                        <Route path="/risk-consultation" element={<RiskConsultation />} />
                    </Route>

                    {/* 
                        snowflake auth-guard here due to the fact that hinshaw admins are expected
                        to be registered in Hinshaw's Azure IDP; they will authenticate via SAML, user data stored
                        elsewhere, so we're blocking any application user management, both React- and WP-sides, to
                        respect that source of truth. We restrict by hinshaw admins as a proxy for "auth'd by Azure,"
                        which we don't have a way to check directly 
                    */}
                    <Route
                        path="/profile"
                        element={
                            <ExcludeHinshawGuard>
                                <Profile />
                            </ExcludeHinshawGuard>
                        }
                    />
                </Route>
                <Route path="/*" element={<Navigate replace to="/error/404" />} />
                {isDev && <Route path="/error/sentry-test" Component={ErrorSentry} />}
            </SentryRoutes>
        </Gate>
    </Suspense>
);

export default App;
