import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useLocation, Link, Outlet } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import { styled, lighten, useTheme } from '@mui/material/styles';

import Button from '../components/button';
import Footer from '../components/footer';
import HinshawDarkLogo from '../images/hinshaw-dark.png';
import Tokens from '../styles/tokens';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const Logo = styled('img')(({ theme }) => ({
    width: '150px',
    maxHeight: theme.mixins.toolbar.height,
}));

const Content = styled('div')(({ theme, hideHeader }) => ({
    // https://css-tricks.com/books/greatest-css-tricks/hard-stop-gradients/
    background: `linear-gradient(
        to bottom,
        ${theme.palette.primary.main},
        ${theme.palette.primary.main} 60%,
        ${Tokens.colors.secondaryBlue} 60%,
        ${Tokens.colors.secondaryBlue})
    `,
    height: `calc(100vh - ${hideHeader ? '0px' : theme.mixins.toolbar.height})`,
    overflowY: 'auto',
}));

const PublicLayout = () => {
    const location = useLocation();
    // hide header on home page, as main content covers roughly the same purpose
    const isErrorPage = location.pathname === '/error/404' || location.pathname === '/error/500';
    const hideHeader = location.pathname === '/';
    const theme = useTheme();

    return (
        <>
            {!hideHeader && (
                <>
                    <AppBar
                        component="nav"
                        position="fixed"
                        sx={{ backgroundColor: lighten(theme.palette.primary.main, 0.15), pl: 4 }}
                    >
                        <Toolbar>
                            <Link to="/">
                                <Logo src={HinshawDarkLogo} alt="Hinshaw Logo" />
                            </Link>
                            {!isErrorPage && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        justifyContent: 'flex-end',
                                        mr: 2,
                                    }}
                                >
                                    <Box>
                                        {' '}
                                        {/* Nesting ensures flexGrow nudges button group all the way to the right without stretching button height */}
                                        <Button to="/signup" variant="text-light" sx={{ mr: 2 }}>
                                            Create Account
                                        </Button>
                                        <Button to="/" variant="light" sx={{ p: 0 }}>
                                            Log In
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </Toolbar>
                    </AppBar>
                    <Offset />
                </>
            )}
            <Content id="ll-global-scroll-container" hideHeader={hideHeader}>
                <Container
                    component="main"
                    maxWidth="xl"
                    sx={{
                        // padding to prevent margin collapse
                        pt: 10,
                        pb: 5,
                        // Our main content should fill all available space outside the header and footer (both of which are theme.mixins.toolbar.height tall),
                        // This means, at minimum, the space remaining in the viewport's height after those toolbars.
                        // If taller, though, the content should push the footer down per the content's intrinsic height (fit-content)
                        // footer calc must be kept in sync w/ same logic in components/footer
                        minHeight: {
                            xs: `calc(100vh - ${hideHeader ? '0px' : theme.mixins.toolbar.height} - calc(${theme.mixins.footer.height} * 2))`,
                            lg: `calc(100vh - ${hideHeader ? '0px' : theme.mixins.toolbar.height} - ${theme.mixins.footer.height})`,
                        },
                        height: 'fit-content',
                    }}
                >
                    <Outlet />
                </Container>
                <Footer />
            </Content>
        </>
    );
};

export default PublicLayout;
